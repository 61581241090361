import Vue from 'vue';

import StaticScopeProvider from './StaticScopeProvider';
import StaticString from './StaticString';
import StaticStringInjector from './StaticStringInjector';
import StaticStringRegistry from './StaticStringRegistry';

Vue.component('static-scope-provider', StaticScopeProvider);
Vue.component('static-string', StaticString);
Vue.component('static-string-injector', StaticStringInjector);
Vue.component('static-string-registry', StaticStringRegistry);
