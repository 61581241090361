export default {
  state: {
    highlightStatics: false,
    highlightDeepLinks: false
  },

  toggleStaticsHighlight(value = !this.state.highlightStatics) {
    this.state.highlightStatics = value;
  },

  toggleDeepLinksHighlight(value = !this.state.highlightDeepLinks) {
    this.state.highlightDeepLinks = value;
  }
};
