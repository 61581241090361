import axios from 'axios';

const attachCSRFHeaders = function(data, headers) {
  const csrfMeta = document.querySelector('meta[name="csrf-token"]');

  headers['X-CSRF-Token'] = csrfMeta ? csrfMeta.content : '';
  headers['X-Requested-With'] = 'XMLHttpRequest';

  return data;
};

let instance = axios.create({
  // We need the default transformer here to allow passing objects to axios
  transformRequest: [attachCSRFHeaders, ...axios.defaults.transformRequest]
});

export default instance;
